import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/iso-noise-reduction",
  "date": "2014-06-14",
  "title": "ISO NOISE REDUCTION",
  "author": "admin",
  "tags": ["photography"],
  "featuredImage": "feature.jpg",
  "excerpt": "Using great noise reduction is like upgrading your lens. Why do we pay 16x more for one 50mm lens vs. another?"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`One big reason is that the higher-quality lens lets in more light. When available light is an issue, using a better lens often lets us take photos that we otherwise wouldn't be able to get.`}</p>
    <p>{`It's the same way with noise reduction. You'd normally get ugly image noise by using a fast shutter speed in low light, but noise reduction software can make you feel like there was never any noise at all, almost.`}</p>
    <p>{`Like a $1,500 lens, noise reduction software doesn't just make your photos look better... it allows you to get shots you otherwise would miss.`}</p>
    <p>{`Wildlife photography demands a quick shutter speed, so using Topaz DeNoise or Nik Dfine to can help to extract the noise from photos. In the image above, notice how the detail in the beak and fur is still crisp and sharp, even though the noise is gone.`}</p>
    <p>{`You will only have a split second to grab that perfect wildlife shot before it flies away. There's no room for error in either his judgment or your equipment, so using software will help you to shoot at a higher ISO with more confidence.`}</p>
    <p>{`Here's some more photos Maxis created this way:`}</p>
    <p>{`Technology with a single goal: less noise + more detail.`}</p>
    <h2>{`Noise vs. detail: a tricky problem.`}</h2>
    <p>{`Noise reduction by itself is easy: just blur the image and voila, no more noise! No, the hard part is: how do you reduce noise while `}<inlineCode parentName="p">{`perfectly preserving crisp detail`}</inlineCode>{`?`}</p>
    <p>{`In the photo on the right, which part of the pillar is good detail vs. bad noise? Forget about technology for a minute... how are `}<inlineCode parentName="p">{`we`}</inlineCode>{` even supposed to tell the difference? And if we can't even tell, how can we expect automated software to?`}</p>
    <p>{`It's a tough problem to solve. That's why most noise reduction tools also destroy vital image detail. `}<strong parentName="p">{`DeNoise or Nik Dfine do not.`}</strong>{`" Defy the tradition and get the best of both worlds.`}</p>
    <p>{`Here's how it works: DeNoise or Nik Dfine generate a custom full-resolution noise profile for each image it processes. This makes it surprisingly accurate at finding the difference between noise and detail - often even more accurate than humans!`}</p>
    <p>{`Other software either use pre-built noise profiles or only look at a very small section of the image. Although the DeNoise or Nik Dfine technology is much slower, you'll forget all about speed when you see the image quality of the final results.`}</p>
    <p>{`These software analyze patterns in the entire image to remove noise and recover underlying detail. `}<br clear="none" />{`In many cases it can make ISO 1600 look like ISO 100. This technology is very new, and so far we've been thrilled with its positive reception.`}</p>
    <p>{`So, armed with this technology, let's take a look at this photo again. Instead of looking at only the pillar, using software to process the whole photo so it can more accurately determine the specific noise profile. Hover over the image to see the results.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      